import { setSession, store } from '../store';
import {getEnvironmentVariable, isEmpty} from './common';

export const REQUEST_METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
  POST: 'POST'
};

const getSessionToken = () => {
  const sessionToken = localStorage.getItem('session');
  return sessionToken || '';
};

export const setSessionToken = (val) => {
  localStorage.setItem('session', val);
  store.dispatch(setSession(val));
};

export const fetchServer = async (
  urlSuffix,
  method = REQUEST_METHODS.GET,
  body = null,
  json = false
) => {
  const requestHeaders = new Headers({ Authorization: getSessionToken() });
  const options = {
    method: method
  };
  if (!isEmpty(body)) {
    options['body'] = body;
    if (json) {
      requestHeaders.append('Content-Type', 'application/json');
      options['body'] = JSON.stringify(options['body']);
    }
  }
  options['headers'] = requestHeaders;
  return fetch(`${getEnvironmentVariable("REACT_APP_SERVER_URL")}/${urlSuffix}`, options)
    .then(async (response) => {
      if (response.status === 401) {
        const responseJson = await response.json();
        if (responseJson['msg'] === 'Session Expired') {
          setSessionToken(null);
        }
      } else if (response.status >= 500) {
        alert('Server Unavailable');
        setSessionToken(null);
      }
      return response;
    })
    .catch((err) => {
      console.warn(`Failed to fetch server: ${err}`);
      setSessionToken(null);
    });
};

export const display404 = (navigate, msg = '') => {
  return navigate('/status/404', { state: { displayMessage: msg } });
};

export const fetchConfig = async (configName) => {
  return await fetchServer(`configs/${configName}`).then((response) =>
      response.ok? response.json(): {}
  );
};

export const setConfig = async (configName, data) => {
  return await fetchServer(
    `configs/${configName}`,
    REQUEST_METHODS.PUT,
    data,
    true
  );
};
